import { useEffect, useState } from 'react';
import styles from './SearchMain.module.css';
import SearchCard from './SearchCard.js';

function SearchMain({ searchQuery }) {
    const [searchResult, setSearchResult] = useState([]);
    const apiRequest = async (url, method) => {
        let result;
        try {
            let response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const getSearchResult = async () => {
            const lat = localStorage.getItem('lat');
            const lon = localStorage.getItem('lon');
            await apiRequest(`https://stockscout.eu/stockscoutAPI/public/findProductsByQuery?search=${searchQuery}&lat=${lat}&lon=${lon}&max_distance=1000`, 'GET')
                .then((result) => setSearchResult(result))
                .catch((error) => console.log(error));
        }
        getSearchResult();
    }, [searchQuery]);

    useEffect(() => {
        // console.log(searchResult)
        printSearchResult();
    }, searchResult)

    const printSearchResult = () => {
        let itemsArray = [];
        for (let i in searchResult) {
            console.log(searchResult[i])
            itemsArray.push(<SearchCard key={i} productId={searchResult[i].product_id} imagePath={searchResult[i].product_photo} categories={searchResult[i].category} price={searchResult[i].product_price} units={searchResult[i].product_price_per} name={searchResult[i].product_name} description={searchResult[i].product_description} shopId={searchResult[i].shop_id} shopName={searchResult[i].shop_name} />);
        }
        return itemsArray;
    }

    return (
        <div className={styles.search_main}>
            <div>
                <div className={styles.search_filters}>filters</div>
                <div className={styles.search_result}>
                    {printSearchResult()}
                </div>
                {/* <div>shadow</div>
            <div>info page</div> */}
            </div>
        </div>
    )
}
export default SearchMain;