import styles from './Header.module.css';
import { Link } from 'react-router-dom';
import Logo from './Logo.js';
import Search from './Search.js';
import LocationIco from './LocationIco.js';
import ProfileIco from './ProfileIco.js';
import LocationModal from './LocationModal.js';
import ProfileModal from './ProfileModal.js';
import Shadow from './Shadow.js';
import BurgerMenu from './BurgerMenu.js';
import { useEffect, useState } from 'react';

function Header({ setSearchQuery, setLocationListener }) {
    const [isOpenLocationModal, setIsOpenLocationModalOpen] = useState(false);
    const [location, setLocation] = useState('London, Great Britan');
    const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);

    const toggleLocationModal = () => {
        setIsOpenLocationModalOpen(!isOpenLocationModal);
    }

    const closeLocationModal = () => {
        setIsOpenLocationModalOpen(false);
    }

    // save location value in localstorage, setLocation and close location modal
    const saveLocation = (value) => {
        localStorage.setItem('location', value);
        setLocation(value);
        closeLocationModal();
        setLocationListener(Math.random());
    }

    useEffect(() => {
        // check if geolocation is enabled and nothing in localstorage
        function getIniLocation() {
            if ("geolocation" in navigator && !checkLocalLocation()) {
                navigator.geolocation.getCurrentPosition((pos) => {
                    let lat = pos.coords.latitude;
                    let lon = pos.coords.longitude;
                    getLocation(lat, lon)
                        .then((location) => {
                            setLocation(`${location[0]}, ${location[1]}`);
                        })
                });
            }
        }

        // check if localstorage not empty, then save localstorage value in location
        // if it's  empty, open locationModal
        function checkLocalLocation() {
            let storageLocation = localStorage.getItem('location');
            if (storageLocation == '' || storageLocation == null) {
                setIsOpenLocationModalOpen(true);
                return false;
            }
            setLocation(storageLocation);
            setIsOpenLocationModalOpen(false);
            return true;
        }

        // get city and country
        async function getLocation(lat, lon) {
            localStorage.setItem('lat', lat);
            localStorage.setItem('lon', lon);

            let token = 'pk.77aa7513af537fe39f8b9b8fe71d3aa3';
            let url = `https://us1.locationiq.com/v1/reverse?key=${token}&lat=${lat}&lon=${lon}&normalizeaddress=1&format=json&`;
            const options = { 'method': 'GET', headers: { accept: 'application/json', 'accept-language': 'native' } };
            const response = await fetch(url, options);
            const data = await response.json();
            return [data.address.city, data.address.country];
        }

        getIniLocation();
    }, []);

    const toggleProfileModal = () => {
        setIsOpenProfileModal(!isOpenProfileModal);
    }

    useEffect(() => {
        isOpenProfileModal ? disableScroll() : enableScroll();
    }, [isOpenProfileModal]);

    const disableScroll = () => {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
    };

    const enableScroll = () => {
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.width = '';
    };

    return (
        <div className={styles.header}>
            <div className={styles.header_wrapper}>
                <div>
                    <Link to='/'>
                        <Logo />
                    </Link>
                    <Search setSearchQuery={setSearchQuery} />
                </div>
                <div>
                    <a href="#" onClick={toggleLocationModal}>
                        <LocationIco />
                    </a>
                    <LocationModal location={location} saveLocation={saveLocation} closeLocationModal={closeLocationModal} isModalOpen={isOpenLocationModal} />
                    <a href="#" onClick={toggleProfileModal}>
                        <ProfileIco />
                    </a>
                    {isOpenProfileModal && <ProfileModal onClose={toggleProfileModal} />}
                </div>
                {isOpenProfileModal && <Shadow onClose={toggleProfileModal} />}
                <BurgerMenu isOpenLocationModal={isOpenLocationModal} closeLocationModal={closeLocationModal} isOpenProfileModal={isOpenProfileModal} location={location} saveLocation={saveLocation} toggleLocationModal={toggleLocationModal} toggleProfileModal={toggleProfileModal} />
            </div>
        </div>
    )
}
export default Header;