import React, { useState, useEffect } from 'react';
import styles from './BurgerMenu.module.css';
import LocationIco from './LocationIco';
import ProfileIco from './ProfileIco';
import LocationModal from './LocationModal';
import ProfileModal from './ProfileModal';
import Shadow from './Shadow';

function BurgerMenu({ isOpenLocationModal, closeLocationModal, isOpenProfileModal, location, saveLocation, toggleLocationModal, toggleProfileModal }) {
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    const toggleIsBurgerOpen = () => {
        setIsBurgerOpen(!isBurgerOpen);
        closeLocationModal();
    }

    useEffect(() => {
        isOpenProfileModal ? disableScroll() : enableScroll();
    }, [isOpenProfileModal]);
    useEffect(() => {
        isBurgerOpen ? disableScroll() : enableScroll();
    }, [isBurgerOpen]);
    
    const disableScroll = () => {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
    };

    const enableScroll = () => {
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.width = '';
    };
    return (
        <div className={styles.burger_menu}>
            {!isBurgerOpen ?
                <div className={styles.burger_menu_icon} onClick={toggleIsBurgerOpen}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 6H21M3 12H21M3 18H21" stroke="#E45C2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                :
                !isOpenProfileModal ?
                    <div className={styles.burger_menu_icon} onClick={toggleIsBurgerOpen}>
                        <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 8.94244L11.7714 12.7138C11.8971 12.8352 12.0655 12.9024 12.2403 12.9009C12.4151 12.8994 12.5823 12.8293 12.7059 12.7057C12.8295 12.582 12.8996 12.4148 12.9012 12.24C12.9027 12.0652 12.8355 11.8968 12.714 11.7711L8.94271 7.99977L12.714 4.22844C12.8355 4.10271 12.9027 3.9343 12.9012 3.75951C12.8996 3.58471 12.8295 3.4175 12.7059 3.2939C12.5823 3.17029 12.4151 3.10018 12.2403 3.09866C12.0655 3.09714 11.8971 3.16434 11.7714 3.28577L8.00004 7.05711L4.22871 3.28577C4.10241 3.16734 3.93499 3.10268 3.76187 3.1055C3.58874 3.10831 3.42351 3.17836 3.30112 3.30084C3.17873 3.42331 3.10879 3.5886 3.1061 3.76172C3.10341 3.93485 3.16819 4.10222 3.28671 4.22844L7.05738 7.99977L3.28604 11.7711C3.22237 11.8326 3.17158 11.9062 3.13664 11.9875C3.1017 12.0688 3.08331 12.1563 3.08254 12.2448C3.08178 12.3334 3.09864 12.4211 3.13216 12.5031C3.16568 12.585 3.21519 12.6594 3.27778 12.722C3.34038 12.7846 3.41481 12.8341 3.49674 12.8677C3.57867 12.9012 3.66646 12.918 3.75498 12.9173C3.8435 12.9165 3.93098 12.8981 4.01231 12.8632C4.09365 12.8282 4.16721 12.7774 4.22871 12.7138L8.00004 8.94244Z" fill="#E45C2A" />
                        </svg>
                    </div>
                    :
                    ''
            }
            <div className={`${!isBurgerOpen ? styles.burger_menu_content_wrapper_hide : ''} ${styles.burger_menu_content_wrapper}`}>
                <div className={`${!isBurgerOpen ? styles.burger_menu_content_hide : ''} ${styles.burger_menu_content}`}>
                    <div>
                        <a href="#" onClick={toggleLocationModal}>
                            <LocationIco />
                        </a>
                        <LocationModal location={location} saveLocation={saveLocation} closeLocationModal={closeLocationModal} isModalOpen={isOpenLocationModal} />
                        <a href="#" onClick={toggleProfileModal}>
                            <ProfileIco />
                        </a>
                        {isOpenProfileModal && <ProfileModal onClose={toggleProfileModal} />}
                        {/* {isOpenProfileModal && <Shadow onClose={toggleProfileModal} />} */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BurgerMenu;