import styles from './HeaderIcon.module.css';


function LocationIco() {
    return (
        <div className={styles.header_ico}>
            <div>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_60_8)">
                        <path d="M15.9998 32C15.9998 32 4.7998 17.392 4.7998 11.2C4.7998 9.7292 5.0895 8.27279 5.65235 6.91395C6.21521 5.5551 7.04019 4.32042 8.08021 3.2804C9.12022 2.24039 10.3549 1.4154 11.7138 0.852549C13.0726 0.289697 14.529 0 15.9998 0C17.4706 0 18.927 0.289697 20.2859 0.852549C21.6447 1.4154 22.8794 2.24039 23.9194 3.2804C24.9594 4.32042 25.7844 5.5551 26.3473 6.91395C26.9101 8.27279 27.1998 9.7292 27.1998 11.2C27.1998 17.392 15.9998 32 15.9998 32ZM15.9998 14.4C16.8485 14.4 17.6624 14.0629 18.2625 13.4627C18.8627 12.8626 19.1998 12.0487 19.1998 11.2C19.1998 10.3513 18.8627 9.53737 18.2625 8.93726C17.6624 8.33714 16.8485 8 15.9998 8C15.1511 8 14.3372 8.33714 13.7371 8.93726C13.1369 9.53737 12.7998 10.3513 12.7998 11.2C12.7998 12.0487 13.1369 12.8626 13.7371 13.4627C14.3372 14.0629 15.1511 14.4 15.9998 14.4Z" style={{fill: "var(--accent)"}} />
                    </g>
                    <defs>
                        <clipPath id="clip0_60_8">
                            <rect width="32" height="32" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <span>Location</span>
        </div>
    )
}
export default LocationIco;