import styles from './Footer.module.css';
function Footer() {
    return (
        <div className={styles.footer}>
            <a href="https://github.com/arya065/stockscout" target='_blank'>
                <img src="/footer resources/logo footer.png" alt="Logo footer" />
            </a>
            <div className={styles.footer_center_block}>
                <div>
                    <a href="#">About us</a>
                    <a href="#">Platfrom rules</a>
                    <a href="#">Privacy Policy</a>
                    <a href="#">Cookies Policy</a>
                </div>
                <div className={styles.footer_social}>
                    <img src="/footer resources/telegram.svg" alt="telegram icon" />
                    <img src="/footer resources/linkedin.svg" alt="linkedin icon" />
                    <img src="/footer resources/github.svg" alt="github icon" />
                    <img src="/footer resources/youtube.svg" alt="youtube icon" />
                    <img src="/footer resources/instagram.svg" alt="instagram icon" />
                </div>
            </div>
            <div className={styles.footer_form}>
                <p>Leave us your feedback</p>
                <form action="">
                    <div className={styles.form_line}>
                        <label htmlFor="mail">Email:</label>
                        <input type="email" name="mail" id="mail" placeholder='Email' />
                    </div>
                    <div className={styles.form_line}>
                        <label htmlFor="name">Name:</label>
                        <input type="text" name="name" id="name" placeholder='Name' />
                    </div>
                    <div className={styles.form_line}>
                        <label htmlFor="comment">Comments:</label>
                        <input type="text" name="comment" id="comment" placeholder='Your comments' />
                    </div>
                    <div className={styles.footer_form_btn}>Submit</div>
                </form>
            </div>
        </div>
    )
}
export default Footer;