import { useEffect, useState } from 'react';
import styles from './Search.module.css';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Link, useNavigate } from 'react-router-dom';

function Search({ setSearchQuery }) {
    const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();

    const writeInput = (el) => {
        setInputValue(el.currentTarget.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSearchQuery(inputValue);
        navigate('/search');
    }

    const searchFindClick = () => {
        if (inputValue !== '') {
            setSearchQuery(inputValue);
        }
    }

    useEffect(() => {
        setInputValue(transcript);
    }, [transcript]);

    const startRecording = () => {
        SpeechRecognition.startListening({ continuous: false, language: "en-US" });
    }

    if (!browserSupportsSpeechRecognition) {
        alert('Your browser does not support Speech Recognition.');
    }


    return (
        <div className={styles.search_wrapper}>
            <form onSubmit={handleSubmit}>
                <div className={styles.search_input}>
                    <input type="text" name="header_search" placeholder="Search Product Name" onInput={writeInput} value={inputValue} />
                </div>
                <div className={styles.search_controls_wrapper}>
                    <div className={styles.search_find_btn} onClick={searchFindClick}>
                        <Link to='/search'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 21L17.5 17.5M17 10C17 10.9193 16.8189 11.8295 16.4672 12.6788C16.1154 13.5281 15.5998 14.2997 14.9497 14.9497C14.2997 15.5998 13.5281 16.1154 12.6788 16.4672C11.8295 16.8189 10.9193 17 10 17C9.08075 17 8.1705 16.8189 7.32122 16.4672C6.47194 16.1154 5.70026 15.5998 5.05025 14.9497C4.40024 14.2997 3.88463 13.5281 3.53284 12.6788C3.18106 11.8295 3 10.9193 3 10C3 8.14348 3.7375 6.36301 5.05025 5.05025C6.36301 3.7375 8.14348 3 10 3C11.8565 3 13.637 3.7375 14.9497 5.05025C16.2625 6.36301 17 8.14348 17 10Z" style={{ stroke: "var(--accent-text)" }} stroke-width="2" stroke-linecap="round" />
                            </svg>
                        </Link>
                    </div>
                    <div className={styles.search_micro_btn} onClick={startRecording}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C12.7956 2 13.5587 2.31607 14.1213 2.87868C14.6839 3.44129 15 4.20435 15 5V11C15 11.7956 14.6839 12.5587 14.1213 13.1213C13.5587 13.6839 12.7956 14 12 14C11.2044 14 10.4413 13.6839 9.87868 13.1213C9.31607 12.5587 9 11.7956 9 11V5C9 4.20435 9.31607 3.44129 9.87868 2.87868C10.4413 2.31607 11.2044 2 12 2ZM19 11C19 14.53 16.39 17.44 13 17.93V21H11V17.93C7.61 17.44 5 14.53 5 11H7C7 12.3261 7.52678 13.5979 8.46447 14.5355C9.40215 15.4732 10.6739 16 12 16C13.3261 16 14.5979 15.4732 15.5355 14.5355C16.4732 13.5979 17 12.3261 17 11H19Z" fill="#FCEDE7" style={{ stroke: "var(--accent-text)" }} stroke-width=".2" />
                        </svg>
                    </div>
                </div>
            </form>
        </div >
    )
}
export default Search;