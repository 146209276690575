import styles from './MainUser.module.css';
import MainLinearBlock from './MainLinearBlock.js';
import CTABlock from './CTABlock.js';
import { useEffect, useState } from 'react';

function MainUser({locationListener}) {
    const [categories, setCategories] = useState([]);
    const [latestSearch, setLatestSearch] = useState([]);
    const [shopsNearby, setShopsNearby] = useState([]);
    const [popularProducts, setPopularProducts] = useState([]);
    const [fastSearch, setFastSearch] = useState([]);

    const apiRequest = async (url, method) => {
        let result;
        try {
            let response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    };
    
    useEffect(() => {
        const getCategories = async () => {
            await apiRequest('https://stockscout.eu/stockscoutAPI/public/getCategories', 'GET')
                .then((result) => setCategories(result))
                .catch((error) => console.log(error));

        }
        const getLatestSearch = async () => {
            apiRequest('https://stockscout.eu/stockscoutAPI/public/getRecent?user_id=1', 'GET')
                .then((result) => setLatestSearch(result))
                .catch((error) => console.log(error));
        }
        const getShopsNearby = async () => {
            const lat = localStorage.getItem('lat');
            const lon = localStorage.getItem('lon');
            apiRequest(`https://stockscout.eu/stockscoutAPI/public/getShopsNearby?lat=${lat}&lon=${lon}?&max_distance=5`, 'GET')
                .then((result) => setShopsNearby(result))
                .catch((error) => console.log(error));
        }
        const getPopularProducts = async () => {
            apiRequest('https://stockscout.eu/stockscoutAPI/public/getPopularProducts', 'GET')
                .then((result) => setPopularProducts(result))
                .catch((error) => console.log(error));
        }
        const getFastSearch = async () => {
            apiRequest('https://stockscout.eu/stockscoutAPI/public/getFastSearch', 'GET')
                .then((result) => setFastSearch(result))
                .catch((error) => console.log(error));
        }

        getCategories();
        getLatestSearch();
        getShopsNearby();
        getPopularProducts();
    }, [locationListener]);

    let items = [
        {
            "product_id": 1,
            "image": "meat.png",
            "name": "Meat",
            "description": "Ideal para todo tipo de preparaciones, desde asados y guisos hasta parrilladas y filetes a la plancha.",
        },
        {
            "product_id": 2,
            "image": "vegetables.png",
            "name": "Patata",
            "description": "Con su textura ideal y su sabor natural, es perfecta para preparar purés cremosos, patatas fritas crujientes, guisos sustanciosos o ensaladas frescas.",
        },
        {
            "product_id": 3,
            "image": "cheese.png",
            "name": "Queso",
            "description": "Disfruta del sabor inconfundible de nuestro Queso, elaborado con leche de la más alta calidad para ofrecerte una experiencia única en cada bocado",
        }
    ];

    return (
        <div className={styles.main_user}>
            <MainLinearBlock items={categories} blockDescription={'Popular categories'} />
            <MainLinearBlock items={latestSearch} blockDescription={'Your latest search'} />
            <MainLinearBlock items={shopsNearby} blockDescription={'Shops near you'} />
            <CTABlock />
            <MainLinearBlock items={items} blockDescription={'Try fast search'} />
            <MainLinearBlock items={popularProducts} blockDescription={'Popular products'} />
        </div>
    )
}
export default MainUser;