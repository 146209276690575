import styles from './SearchCard.module.css';


function SearchCard({ productId, imagePath, categories, price, units, name, description, shopId, shopName }) {
    const setCategories = () => {
        let itemsArray = [];
        categories = categories.split(', ');
        categories.forEach(category => {
            itemsArray.push(<div>{category}</div>);
        });
        return itemsArray;
    }

    const apiRequest = async (url, method) => {
        let result;
        try {
            let response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    };
    const addToFavorites = () => {
        apiRequest(`https://stockscout.eu/stockscoutAPI/public/addToUserList?product_id=${productId}&user_list_id=3`, 'PUT')
    }
    const addToBuylist = () => {
        apiRequest(`https://stockscout.eu/stockscoutAPI/public/addToUserList?product_id=${productId}&user_list_id=1`, 'PUT')
    }
    const addToRecents = () => {
        apiRequest(`https://stockscout.eu/stockscoutAPI/public/addToUserList?product_id=${productId}&user_list_id=2`, 'PUT')
    }

    return (
        <div className={styles.search_card} onClick={addToRecents}>
            <img src={"./tmp_img/" + imagePath} alt="Card Image" />
            <div className={styles.search_card_btn_wrapper}>
                <div className={styles.search_card_btn} onClick={addToBuylist}>
                    <svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.00016 4.5H13.3335M6.00016 8.5H13.3335M6.00016 12.5H13.3335M3.3335 4.5V4.50667M3.3335 8.5V8.50667M3.3335 12.5V12.5067" style={{ stroke: "var(--accent-text)" }} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div className={styles.search_card_btn} onClick={addToFavorites}>
                    <svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.96666 9.77223L7.60199 14.1262C7.76199 14.2762 7.84199 14.3516 7.93532 14.3702C7.97781 14.3786 8.02151 14.3786 8.06399 14.3702C8.15866 14.3516 8.23799 14.2769 8.39732 14.1262L13.0327 9.7729C13.6623 9.18175 14.0487 8.377 14.1164 7.51604C14.1841 6.65508 13.9281 5.79983 13.3987 5.11756L13.192 4.85156C11.88 3.16089 9.24666 3.44423 8.32466 5.37623C8.29533 5.43745 8.24928 5.48914 8.19183 5.52532C8.13439 5.5615 8.06788 5.58069 7.99999 5.58069C7.9321 5.58069 7.8656 5.5615 7.80815 5.52532C7.7507 5.48914 7.70466 5.43745 7.67532 5.37623C6.75332 3.44423 4.11999 3.16023 2.80799 4.85156L2.60132 5.11823C2.07223 5.8004 1.81653 6.65536 1.88422 7.51601C1.9519 8.37667 2.3381 9.18115 2.96732 9.77223H2.96666Z" style={{ stroke: "var(--accent-text)" }} stroke-width="1.5" />
                    </svg>
                </div>

            </div>
            <div className={styles.search_card_info}>
                <div className={styles.search_card_categories}>{setCategories()}</div>
                <div className={styles.search_card_price}>
                    <span>{price}$</span>
                    <span>/{units}</span>
                </div>
                <div className={styles.search_card_name}>{name}</div>
                <div className={styles.search_card_description}>{description}</div>
                <div className={styles.search_card_shop_name}>{shopName}</div>
            </div>
        </div>
    )
}
export default SearchCard;