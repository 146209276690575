import styles from './CTABlock.module.css';

function CTABlock() {
    return (
        <div className={styles.cta_block}>
            <div className={styles.cta_description}>
                <h2>Have a business?</h2>
                <div>
                    <p>Want to boost your sales and increase your visbility?</p>
                    <p>Join our platform today!</p>
                </div>
            </div>
            <div className={styles.cta_btn}>Join us today</div>
        </div>
    )
}
export default CTABlock;