import styles from './Shadow.module.css';


function Shadow({ onClose }) {
    const handleClick = () => {
        onClose();
    }
    return (
        <div className={styles.shadow} onClick={handleClick}></div>
    )
}
export default Shadow;