import './App.css';
import Header from './components/header/Header.js';
import MainUser from './components/main_user/MainUser.js';
import Footer from './components/footer/Footer.js';

import { Route, Routes } from 'react-router-dom';
import SearchMain from './components/search_page/SearchMain.js';
import { useState } from 'react';
function App() {
  const [searchQuery, setSearchQuery] = useState();
  const [locationListener, setLocationListener] = useState();
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<><Header setSearchQuery={setSearchQuery} setLocationListener={setLocationListener} /><MainUser locationListener={locationListener} /><Footer /></>} />
        <Route path='/search' element={<><Header setSearchQuery={setSearchQuery} setLocationListener={setLocationListener} /><SearchMain searchQuery={searchQuery} /><Footer /></>} />
        <Route path='/settings' element={<><Header setSearchQuery={setSearchQuery} setLocationListener={setLocationListener} /><SearchMain /><Footer /></>} />
      </Routes>
    </div>
  );
}

export default App;
