import styles from './MainLinearBlock.module.css';
import LinearBlockItem from './LinearBlockItem';

function MainLinearBlock({ LinearNoScroll, items, blockDescription }) {

    const printItems = () => {
        let itemsArr = [];
        for (let i in items) {
            itemsArr.push(<LinearBlockItem key={i} productId={items[i].product_id} imgPath={`/tmp_img/${items[i].image}`} name={items[i].name} description={items[i].description} />);
        }
        return itemsArr;
    }
    return (
        <div className={styles.linear_block}>
            <h2 className={styles.linear_block_title}>{blockDescription}</h2>
            <div className={`${LinearNoScroll ? styles.no_scroll : ''}`}>
                {printItems()}
            </div>
        </div>
    )
}
export default MainLinearBlock;