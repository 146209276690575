import styles from './LinearBlockItem.module.css';

function LinearBlockItem({ name, description, imgPath }) {
    return (
        <div className={styles.linear_block_item}>
            <img src={imgPath} alt="product image" />
            <div className={styles.linear_block_description}>
                <p>{name}</p>
            </div>
        </div>
    )
}
export default LinearBlockItem;